import React, { useState, useEffect, useCallback } from "react";
import useStore from "../../store";
import { updateAdvert } from "../../api";
import { FormControl, FormControlLabel, TextField } from "@mui/material";
import { debounce } from 'lodash';
import Checkbox from "@mui/material/Checkbox";
import { useFormContext } from "react-hook-form";

export const EditCostDetails = ({errorFields}) => {
  const { register, formState, setValue} = useFormContext();
  const { errors } = formState;
  const advert = useStore((state) => state.advertDetails);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails)
  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);
  const [nebenkosten, setNebenkosten] = useState(0);
  const [mietkosten, setMietkosten] = useState(0);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleUpdate = useCallback((token, advertId, key, value) => {
    return updateAdvert(token, { id: advertId, key, value });
  }, []);

  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, key, value) => {
      handleUpdate(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );

  const handleChangeOverAll = (key) => async(event) => {
    const value = event.target.value;
    // Update the form value
    setValue(key, value);
    updateAdvertDetails(key, value)
    // Call the debounced function
    debouncedUpdateAdvert(token, advertId,key, value)
  };

  useEffect(() => {
    errorFields(errors);
  }, [errors])

  return (
    <>
      <FormControl sx={{ width: "100%", marginTop: 5, marginBottom: "15px" }}>
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
          Kosten
        </h2>
        <TextField
          {...register("rent", {required: true})}
          defaultValue={advert.rent}
          required
          error={Boolean(errors?.rent)}
          helperText="Mietkosten angaben sind pflicht"
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>CHF</span>,
          }}
          onChange={async (e) => {
            if (e.target.value === "") {
              // If the field is empty, set nebenkosten to 0
              setMietkosten(0);
            } else {
              // Otherwise, update nebenkosten with the entered value
              setMietkosten(e.target.value);

              debouncedUpdateAdvert(token,advertId, "rent", parseInt(e.target.value));
              updateAdvertDetails("rent", parseInt(e.target.value));
            
            }
          }}
          type="number"
          id="outlined-required"
          label="Miete"
        ></TextField>
      </FormControl>
      <div className="toggle-rent-infos mb-10">
        <FormControlLabel
          control={
            <Checkbox
              {...register("includeAdditionalCosts")}
              defaultChecked
              onChange={handleChange}
            />
          }
          label="Nebenkosten sind inkludiert"
        />

        <div className="flex gap-5 flex-wrap">
          <>
            {!checked && (
              <>
                <TextField
                  sx={{
                    width: "100%",
                    "@media (min-width: 900px)": { width: "48.0%" },
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    endAdornment: <span>CHF</span>,
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      // If the field is empty, set nebenkosten to 0
                      setNebenkosten(0);
                    } else {
                      // Otherwise, update nebenkosten with the entered value
                      setNebenkosten(e.target.value);
                    }
                  }}
                  type="number"
                  id="outlined-required"
                  label="Nebenkosten"
                ></TextField>
                <div className="p-4 border-solid border border-gray-300 rounded flex-1">
                  <p className="font-bold">Mitteaufschlüsselung</p>
                  <div className="detials flex justify-between">
                    <p>Nettomiete:</p>
                    <p>CHF {mietkosten}.-</p>
                  </div>
                  <div className="detials flex justify-between mb-3">
                    <p>Nebenkosten:</p>
                    <p>CHF {nebenkosten}.-</p>
                  </div>
                  <div className="detials flex justify-between border-t border-gray-300">
                    <p className="font-bold">Gesamtmiete:</p>
                    <p className="font-bold">
                      CHF {parseInt(mietkosten) + parseInt(nebenkosten)}
                      .-
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

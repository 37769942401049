import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const domain = process.env.REACT_APP_OAUTH_DOMAIN;
const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const callBackUrl ='https://ads.homego.ch';

const redirectUri = window.location.hostname === 'localhost' ? window.location.origin : callBackUrl;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri:'https://ads.homego.ch/insertion'
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from "react";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";


const featuresAndAmenities = [
    {
      label: "Garage",
      value: "garage",
    },
    {
      label: "Rollstuhlgängig",
      value: "accessible",
    },
    {
      label: "Parkplatz",
      value: "parking",
    },
    {
      label: "Lift",
      value: "elevator",
    },
    {
      label: "Balkon/Terrasse",
      value: "balcony_terrace",
    },
    {
      label: "Garten",
      value: "garden",
    },
    {
      label: "Schwimmbad",
      value: "swimming_pool",
    },
    {
      label: "Fitnessstudio",
      value: "fitness_center",
    },
    {
      label: "Klimaanlage",
      value: "air_conditioning",
    },
    {
      label: "Einbauschränke",
      value: "built_in_closets",
    },
    {
      label: "Alarmanlage",
      value: "alarm_system",
    },
    {
      label: "Fußbodenheizung",
      value: "underfloor_heating",
    },
    {
      label: "Kamin",
      value: "fireplace",
    },
    {
      label: "Gästetoilette",
      value: "guest_toilet",
    },
    {
      label: "Abstellraum",
      value: "storage_room",
    },
    {
      label: "Barrierefreier Zugang",
      value: "accessible_access",
    },
    {
      label: "Videoüberwachung",
      value: "video_surveillance",
    },
    {
      label: "Haustiere erlaubt",
      value: "pets_allowed",
    },
    {
      label: "Kinderfreundlich",
      value: "child_friendly",
    },
    {
      label: "Solaranlage",
      value: "solar_panels",
    },
    {
      label: "Einliegerwohnung",
      value: "granny_flat",
    },
    {
      label: "Balkonverglasung (Wintergarten)",
      value: "balcony_glazing",
    },
    {
      label: "Dachterrasse",
      value: "roof_terrace",
    },
    {
      label: "Hobbyraum",
      value: "hobby_room",
    },
  ];





const FeatureCheckboxGroup = ({ features, handleChange }) => {
    console.log(features, 'here data')
    return (
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {
            features.map((f, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={f.value || f.checked}
                    onChange={handleChange(index, f)}
                  />
                }
                label={featuresAndAmenities[index].label.replace(/_/g, ' ')}
                sx={{ width: '45%' }}
              />
            ))
         
          }
        </FormGroup>
      );
};

export default FeatureCheckboxGroup;
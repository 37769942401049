import { useRef } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const VideoUploadModal = ({ open, onClose, onUpload }) => {
  const videoCaptureRef = useRef();
  const videoUploadRef = useRef();

  const handleCaptureClick = () => {
    videoCaptureRef.current.click();
  };

  const handleUploadClick = () => {
    videoUploadRef.current.click();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="video-upload-modal"
      aria-describedby="modal-to-upload-video"
      className="fixed inset-0 flex justify-center items-end"
    >
      <div
        className={`bg-white rounded-t-lg shadow-lg p-8 relative flex flex-col items-center w-full max-w-lg transition-transform duration-300 ${
          open ? "translate-y-0" : "translate-y-full"
        }`}
        style={{ maxHeight: "90vh" }}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        >
          <CloseIcon />
        </button>

        {/* Buttons Row */}
        <div className="flex justify-between w-full">
          {/* Record Video Button */}
          <button
            onClick={handleCaptureClick}
            className="flex items-center justify-center bg-[#020d1f] text-white rounded-lg hover:bg-opacity-90 px-4 py-2 w-[48%]"
          >
            <VideoCallIcon className="mr-2" />
            Record
          </button>

          {/* Upload Video Button */}
          <button
            onClick={handleUploadClick}
            className="flex items-center justify-center bg-[#dc278a] text-white rounded-lg hover:bg-opacity-90 px-4 py-2 w-[48%]"
          >
            <CloudUploadIcon className="mr-2" />
            Upload
          </button>
        </div>

        {/* Hidden file inputs */}
        <input
          type="file"
          accept="video/*"
          capture="environment"
          ref={videoCaptureRef}
          style={{ display: "none" }}
          onChange={onUpload}
        />

        <input
          type="file"
          accept="video/*"
          ref={videoUploadRef}
          style={{ display: "none" }}
          onChange={onUpload}
        />
      </div>
    </Modal>
  );
};

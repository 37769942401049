import React, { useEffect } from "react";
import { getUsersAds, deleteAdvert } from "../api";
import {
  objectTypeResolver,
  buildOfferTypeResolver,
  convertIsoDate,
  isISODate,
} from "../utilis/helper";
import useStore from "../store";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../assets/edit.svg";
import { ReactComponent as Trash } from "../assets/trash.svg";
import placeholderImage from '../assets/placeholder.webp';

export default function Dashboard() {
  const currentUser = useStore((state) => state.user);
  const token = useStore((state) => state.jwtToken);
  const setAds = useStore((state) => state.setAds);
  const ads = useStore((state) => state.ads);


  let currentUserId;

  const navigate = useNavigate();

  if (currentUser.id) {
    currentUserId = currentUser.id;
  }

  console.log(token)

  useEffect(() => {
    const fetchAds = async () => {
      if (currentUser.id) {
        const fetchedAds = await getUsersAds(token, currentUserId);
        console.log(fetchedAds)
        if (fetchedAds.adverts.length > 0) {
          setAds(fetchedAds.adverts);
        } else {
          setAds([]); // Ensure ads is an empty array if no adverts are returned
        }
        console.log(fetchedAds);
      }
    };

    fetchAds();
  }, [ads.length, currentUser.id, setAds, token]);

  const deleteAdrts = async (advertId) => {
    await deleteAdvert(token, advertId);
    setAds((prevAds) => prevAds.filter((ad) => ad.id !== advertId));
  };

  const adEditView = (adId) => {
    navigate(`/insertion/${adId}`);
  };


  const PublshStatus = ({ published }) => {
    if (published) {
      return (
        <div className=" status inline-flex gap-2 items-center rounded px-2 font-medium border w-full text-green-900 bg-green-100 border-green-300">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10" />
          <path d="M8 12l2 2 4-4" />
        </svg>
          <span className="text-sm">Veröffentlicht</span>
        </div>
      );
    } else {
      return (
        <div
        className={`status inline-flex gap-2 items-center rounded px-2 font-medium border w-full ${
          published
            ? "text-green-900 bg-green-100 border-green-300"
            : "text-red-900 bg-red-100 border-red-300"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          {published ? (
            <path d="M8 12l2 2 4-4" />
          ) : (
            <path d="M15 9l-6 6M9 9l6 6" />
          )}
        </svg>
        <span>Entwurf</span>
      </div>
      

      ) // You can return null if you don't want to render anything when published is false
    }
  };
  return (
    <div className="bg-[#f7f6f5] h-screen">
   <div className="mt-5 container mx-auto max-w-4xl bg-[#f7f6f5] h-[100%]">
  {Array.isArray(ads) && ads.length > 0 ? (
    ads
      .slice()  // Create a shallow copy of the ads array
      .reverse()  // Reverse the array to show the latest ads on top
      .map((ad) => (
        <div key={ad.id}>
          <div className="card xs:flex-column sm:flex bg-white p-2 border rounded border-gray-200 mb-4 mx-4">
            {ad.images.length > 0 ? (
              <div
                className="picture p-2 h-40 w-[100%] sm:w-[12rem] flex items-center justify-center"
                style={{
                  backgroundImage: `url(https://${ad.images[0].imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            ) : (
              <div className="picture p-2 h-40 w-[100%] sm:w-[12rem] flex items-center justify-center">
                <div
                  className="picture p-2 h-40 w-[100%] sm:w-[12rem] flex items-center justify-center"
                  style={{
                    backgroundImage: `url(${placeholderImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
              </div>
            )}

            <div className="flex-1 ml-4 text-left flex sm:flex-col sm:justify-between">
              <div>
                <div className="text-base my-4">
                  {buildOfferTypeResolver(ad.offerType)} &#8226;{" "}
                  {objectTypeResolver(ad.objectType)} &#8226; {ad.zipCode}{" "}
                  {ad.location} &#8226;{ad.streetNumber} &#8226;{" "}
                  {isISODate(ad.availability)
                    ? convertIsoDate(ad.availability)
                    : ad.availability}
                </div>
                <p className="font-bold text-lg mb-2">{ad.addTitle}</p>
                <PublshStatus published={false} />
              </div>
              <p className="mt-auto sm:pb-2 text-sm">
                Erstellt: {convertIsoDate(ad.createdAt)}
              </p>
            </div>
            <div className="flex sm:flex items-end">
              <button
                onClick={() => adEditView(ad.id)}
                className="flex items-center justify-between m-2 px-2 gap-1 py-1 bg-[#051f49] rounded text-sm text-white"
              >
                <Edit width={15} height={15} size={10} fill="#051f49" />
                Bearbeiten
              </button>
              <button
                onClick={() => deleteAdrts(ad.id)}
                className="flex items-center m-2 px-2 gap-1 py-1 bg-[#dc278a] hover:bg-gray-300 rounded text-sm font-semibold text-white"
              >
                <Trash width={15} height={15} size={10} fill="white" />
                Löschen
              </button>
            </div>
          </div>
        </div>
      ))
  ) : (
    <CircularProgress />
  )}
</div>

    </div>
  );
}

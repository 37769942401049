
import { parseISO, format } from 'date-fns';

const goal = 6;

const goalCase = [];
export const stages = [
  { label: "EG", value: "EG" },
  { label: "UG", value: "UG" },
];
for (let i = 1; i <= goal * 2; i++) {
  goalCase.push(i / 2 + 0.5);
}

for (let i = 1; i <= 11; i++) {
  stages.push({ value: i, label: String(i) });
}

export const resultArray = goalCase.map((value, index) => {
  if (value > 6) {
    return {
      value: value.toFixed(1),
      label: "Mehr als 6",
    };
  } else {
    return {
      value: value.toFixed(1),
      label: value.toFixed(1),
    };
  }
});


export const objectType = [
  {
    value: "none",
    label: "Auswählen",
  },
  {
    value: "appartment",
    label: "Wohnung",
  },
  {
    value: "office",
    label: "Büro",
  },
  {
    value: "house",
    label: "Haus",
  },
  {
    value: "trades",
    label: "Gewerbe",
  },
  {
    value: "parkingPlace",
    label: "Parkplatz",
  },
  {
    value: "buildingPlot",
    label: "Bauland",
  },
];


export const featuresAndAmenities = [
  {
    label: "Garage",
    value: "garage",
  },
  {
    label: "Rollstuhlgängig",
    value: "accessible",
  },
  {
    label: "Parkplatz",
    value: "parking",
  },
  {
    label: "Lift",
    value: "elevator",
  },
  {
    label: "Balkon/Terrasse",
    value: "balcony_terrace",
  },
  {
    label: "Garten",
    value: "garden",
  },
  {
    label: "Schwimmbad",
    value: "swimming_pool",
  },
  {
    label: "Fitnessstudio",
    value: "fitness_center",
  },
  {
    label: "Klimaanlage",
    value: "air_conditioning",
  },
  {
    label: "Einbauschränke",
    value: "built_in_closets",
  },
  {
    label: "Alarmanlage",
    value: "alarm_system",
  },
  {
    label: "Fußbodenheizung",
    value: "underfloor_heating",
  },
  {
    label: "Kamin",
    value: "fireplace",
  },
  {
    label: "Gästetoilette",
    value: "guest_toilet",
  },
  {
    label: "Abstellraum",
    value: "storage_room",
  },
  {
    label: "Barrierefreier Zugang",
    value: "accessible_access",
  },
  {
    label: "Videoüberwachung",
    value: "video_surveillance",
  },
  {
    label: "Haustiere erlaubt",
    value: "pets_allowed",
  },
  {
    label: "Kinderfreundlich",
    value: "child_friendly",
  },
  {
    label: "Solaranlage",
    value: "solar_panels",
  },
  {
    label: "Einliegerwohnung",
    value: "granny_flat",
  },
  {
    label: "Balkonverglasung (Wintergarten)",
    value: "balcony_glazing",
  },
  {
    label: "Dachterrasse",
    value: "roof_terrace",
  },
  {
    label: "Hobbyraum",
    value: "hobby_room",
  },
];

export const customerSegment = [
  {
    label: 'Mieter',
    value: 'tenant'
  },
  {
    label: 'Eigentümer',
    value: 'owner'
  },
  {
    label: 'Firma (MWST)',
    value: 'professional'
  }

];


export const defaultFeatures = [
  { label: 'garage', value: false },
  { label: 'accessible', value: false },
  { label: 'parking', value: false },
  { label: 'elevator', value: false },
  { label: 'balcony_terrace', value: false },
  { label: 'garden', value: false },
  { label: 'swimming_pool', value: false },
  { label: 'fitness_center', value: false },
  { label: 'air_conditioning', value: false },
  { label: 'built_in_closets', value: false },
  { label: 'alarm_system', value: false },
  { label: 'underfloor_heating', value: false },
  { label: 'fireplace', value: false },
  { label: 'guest_toilet', value: false },
  { label: 'storage_room', value: false },
  { label: 'accessible_access', value: false },
  { label: 'video_surveillance', value: false },
  { label: 'pets_allowed', value: false },
  { label: 'child_friendly', value: false },
  { label: 'solar_panels', value: false },
  { label: 'granny_flat', value: false },
  { label: 'balcony_glazing', value: false },
  { label: 'roof_terrace', value: false },
  { label: 'hobby_room', value: false },
];


export const objectTypeResolver = (type) => {
  switch (type) {
    case 'appartment':
      return "Wohnung";
    case 'office':
      return "Büro";
    case 'house':
      return "Haus";
    case 'trades':
      return "Gewerbe";
    case 'parkingPlace':
      return "Parkplätze";
    case 'buildingPlot':
      return "Bauland";
  }
}




export const buildOfferTypeResolver = (offerType) => {
  switch (offerType) {
    case 'rent':
      return "Vermieten";
    case 'buy':
      return "Verkaufen";
  }
};


export const customerTypeResolver = (type) => {
  switch (type) {
    case 'tenant':
      return "Mieter";
    case 'owner':
      return "Eigentümer";
    case 'professional':
      return "Firmenkunde";
  }
};


export const convertIsoDate = (dateString) => {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, 'dd.MM.yyyy');
};


export const isISODate = (dateString) => {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3}Z|Z)?$/;
  return isoRegex.test(dateString);
};
import React, {useState, useEffect } from "react";
import { format} from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import {featuresAndAmenities} from '../utilis/helper'
import Card from "@mui/material/Card";
import { FormProvider, useForm } from "react-hook-form";
import { Summary } from "../components/forms/Summary";
import "../../src/App.css";
import { BasicFeatures } from "../components/forms/BasicFeatures";
import { CostDetails } from "../components/forms/CostDetails";
import { FeatFeatures } from "../components/forms/FeatFeatures";
import { ContactDetails } from "../components/forms/ContactDetails";
import { ImagesVideo } from "../components/forms/ImagesVideo";
import Description from "../components/forms/Description";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createAdvert, getAccessToken, handelFileUpload } from "../api";
import {v4 as uuidv4} from 'uuid';
import useStore from '../store';

const goal = 6;

const goalCase = [];
const stages = [
  { label: "EG", value: "EG" },
  { label: "UG", value: "UG" },
];
for (let i = 1; i <= goal * 2; i++) {
  goalCase.push(i / 2 + 0.5);
}

for (let i = 1; i <= 11; i++) {
  stages.push({ value: i, label: String(i) });
}


export default function Insertion() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();
  const methods = useForm();
  const {
    formState: {}
  } = useForm({
    mode: "onChange",
    defaultValues: {
      zipcode: ""
    }
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedPictures, setSelectedPictures] = useState([]);
  const [step, setStep] = useState(1);
  const [locationGps, setLocationGps] = useState({lat: null, lng: null});
  const [stepIndicator, setStepIndicator] = useState({});
  const [summary, setSummary] = useState();
  const [isGenSlideshow, setIsGenSlideshow] = useState(false);
  const date = useStore(state => state.date);
  const setDate = useStore(state => state.setDate);
  const setAdvertId = useStore(state => state.setAdvertId);
  const jwtToken = useStore(state => state.jwtToken);
  const setVideos = useStore((state) => state.setVideos)
  const videos = useStore((state) => state.videos)
  const advertId = useStore(state => state.advertId);
  const clearFeatures = useStore(state => state.clearFeatures);
  const setAdvertDetails= useStore(state => state.setAdvertDetails);
  const advertDetails= useStore(state => state.advertDetails);
  const clearPictures = useStore(state => state.clearPictures);
  const resetLocationData = useStore(state => state.resetLocationData);

  const currentUser = useStore(state => state.user);
  const [baseObject, setBaseObject] = useState({});
  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);


  useEffect(() => {

    window.scrollTo(0, 0);

    if(!currentUser.id) return;

    clearFeatures();
    clearPictures();
    resetLocationData();
    setVideos(null);

    
    createAdvert({userId: currentUser.id})
      .then((response) => {
        console.log("Advertisement created successfully:", response);
        setAdvertId(response.id);
        setAdvertDetails(response);

        // Handle success, e.g., show a success message to the user
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error creating advertisement:", error);
        // Handle error, e.g., show an error message to the user
      })

  }, []);


  const onSubmit = async(d) => {

    const errorTrackingObject = Object.keys(errors);
    
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }

    
    const dobject = d;

    const featuresList = Object.entries(dobject)
    .filter(([key]) => 
        featuresAndAmenities.some(item => item.value === key)
    )
    .map(([key, value]) => ({ label: key, value }));

console.log('Filtered Features List:', featuresList)

    const basics = {
      advert_UID: id,
      userId: currentUser.id,
      advertId: id,
      rooms: d.rooms,
      adDescription:  d.adDescription,
      addTitle: d.adTitle,
      roomSpace: parseInt(d.roomspace),
      street_number: d.street,
      zipcode: parseInt(d.zipcode),
      lat: locationGps.lat.toString(),
      lng: locationGps.lng.toString(),
      location: d.location,
      availability: date === '' ? d.availability : date,
      rent: parseInt(d.rent),
      etage: d.stage,
      buildYear:  parseInt(d.buildYear),
      renovation: parseInt(d.renovation),
      featuresAndAmenities: featuresList,
      contact: {
        fullName: d.fullName,
        tel: d.phoneNumber,
        email: d.email

      },
      offerType: d.offerType,
      objectType: d.objectType,
      customerCategory: d.customerCategory
    };

    setBaseObject(basics);


   // check if the Array is empty it means has no errors
    if(errorTrackingObject.length === 0 || step === 2) { 
      setStep((prevStep) => prevStep + 1);
    }

    if(step === 5) {
      console.log('send the form');

      console.log(basics)

      createAdvert(basics)
      .then((response) => {
        console.log("Advertisement created successfully:", response.data);
        console.log("Advertisement created successfully:", response.id);
        setAdvertId(response.id);
        setAdvertDetails(response);
        

        if(response.id) {
          handelFileUpload(formData, response.id)
          .then((response) => {
            console.log("Upload created successfully", response);
          })
          .catch((error) => {
            console.error("Error creating advertisement:", error);
          });
        }
        // Handle success, e.g., show a success message to the user
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error creating advertisement:", error);
        // Handle error, e.g., show an error message to the user
      })
    
    }
   

  };
  const handleFilesSelected = (files) => {
    setSelectedFiles(files);
  };


  const handlegeneratingSlideshow = (gen) => {
    setIsGenSlideshow(gen);

  };

  const handlePicturesSelected = (image) => {
    setSelectedPictures(image);
  };

  const handleLocationCordinates = (cords) => {
    setLocationGps(cords);
  };



  const resetDateField = (value) => {
    if(value === 'Nach Vereinbarung' || value === 'Sofort') {
      setDate('');
    } 
  };


  const handleErrors = (error) => {
    if(error) {
      setErrors(error);
    }
  };

  const stepTracker  = (step)  => {
    setStepIndicator(step);
  }
  
  
  
  const handleDateSelection = (date) => {
    if(date && date.$d) {
      const dateFns = new Date(date.$d);
      const result = format(new Date(date.$d), 'yyyy-MM-dd') //The ISO 8601 format (YYYY-MM-DD)
      setDate(result);
  
    }
  };


  const handleNext = (e) => {
    if(step === 3 && videos == null ) {
      alert('please select video');
      return;
    }
    console.log(step)
      if(errors) {
        return;
      } 
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <BasicFeatures handleLocationCordinates={handleLocationCordinates} dateValue={date} resetDateField={resetDateField} stepIndicator={stepTracker} errorFields={handleErrors} onDateChange={handleDateSelection}/>
            <CostDetails errorFields={handleErrors}  />
          </>
        );
      case 2:
        return <FeatFeatures  />;
      case 3:
        return (
          <>
            <ImagesVideo adUID={id} generatingSlideshowMode={handlegeneratingSlideshow} previewPictures={selectedPictures} onSelectedPictures={handlePicturesSelected} onFilesSelected={handleFilesSelected} />
            <Description errorFields={handleErrors}  />
          </>
        );
      case 4:
        return <ContactDetails errorFields={handleErrors}  />;
      case 5:
        return <Summary previewPictures={selectedPictures}  advert={advertDetails} />;

      default:
        return null;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const renderIndicators = () => {
    const indicators = [];
    for (let i = 1; i <= 5; i++) {
      const isActive = i === step;
      indicators.push(
        <span
          key={i}
          className={`block font-bold text-2xl rounded-full h-2 w-[120px] ${
            isActive
              ? "bg-gradient-to-r from-blue-400 to-pink-500"
              : "bg-gray-300"
          }`}

          onClick={() => {
    
          }} // Set step on click
          style={{ cursor: 'pointer' }} // Add pointer cursor for better UX

          
        ></span>
      );
    }
    return indicators;
  };

  return (
    <div className="bg-[#f7f6f5] h-full">
      <div className="container mx-auto max-w-4xl mt-5">
        {(isAuthenticated) ? (
          <>
            <div className="p-2 sm:p-4">
              <div className="flex justify-between items-baseline">
                <h1 className="text-4xl font-bold text-left mt-10 mb-10 sm:mb-10 mt-10">
                  Inserat erstellen
                </h1>
              </div>
              <div className="flex gap-3 justify-center  mb-10 sm:mb-10">{renderIndicators()}</div>
              <div className="Form Wrapper flex-row">
                <FormProvider {...methods}>
                  <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                    <Card variant="outlined" 
                    
                    sx={{
                      width: "100%",
                      padding: 2, // Default padding for smaller screens
                      "@media (min-width: 900px)": { padding: 5 }, // Padding for screens wider than 900px
                    }}
                    
                    >
                      {renderStep()}
                    </Card>
                    <div className="flex gap-4 justify-center sticky bottom-0 bg-white z-50 p-4">
                        {step > 1 && (
                          <button
                            className="border-2 border-indigo-300 rounded-md border-color px-4 py-2 text-gray mt-4 font-bold min-w-36 text-1xl"
                            onClick={handleBack}
                            type='button'
                          >
                            Zurück
                          </button>
                        )}
                        {(step <= 4) && (
                          <button
                            className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 px-4 py-2 text-white mt-4 font-bold min-w-36 text-1xl"
                            onClick={(e) => handleNext(e)}
                          >
                            Weiter
                          </button>
                        )}
                        {step === 5 && (
                          <button
                            className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 px-4 py-2 text-white mt-4 font-bold min-w-36 text-1xl"
                            type="submit"
                          >
                            Inserat erstellen
                          </button>
                        )}
                      </div>
                  </form>
                  
                </FormProvider>
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                   <Alert
                   autoHideDuration={2000}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                    onClose={handleClose}
                  >
                    Das Inserat wurde erfolgreich erstellt
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <div className="h-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="p-4">
                <div>
                  <h4 className="font-bold text-2xl"> Wohnung inserieren</h4>
                  <ul>
                    <li>
                      Erstellen sie ihr Video Inserat mit automatisierten
                      beschreibung mit Ton
                    </li>
                    <li>Sehen Sie alle Bewerbungen auf einen Blick</li>
                    <li>Organisieren Sie Ihre Besichtigungstermine</li>
                  </ul>
                </div>
                <button
                  className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 p-4 text-white mt-4"
                  onClick={() => loginWithRedirect()}
                >
                  Anmelden
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';


const ConfirmDialog = ({ open, onClose, onConfirm }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Bilder Löschen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Die Daten werden unwiederruflich gelöscht, wollen sie den vorgang fortfahren
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='flex items-center m-2 px-2 gap-1 py-1 bg-[#dc278a] rounded text-sm font-semibold text-white"' onClick={onConfirm}><span className='text-white'>Löschen</span></button>
                    <button className='flex items-center justify-between m-2 px-2 gap-1 py-1 bg-[#051f49] rounded text-sm text-white' onClick={onClose} autoFocus>
                        Zurück
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
};



export default ConfirmDialog;
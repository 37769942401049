import React, { useEffect, useState, useCallback } from 'react';
import { updateAdvert, updateAdvertTwo } from "../api";
import useStore from "../../src/store";
import { APIProvider, Map, useMapsLibrary, Marker, PlacesService, useMap } from '@vis.gl/react-google-maps';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useMemo } from 'react';





export default function GoogleMapsAutoComplete() {

  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);
  const setZipcode = useStore((state) => state.setZipcode);
  const setLocation = useStore((state) => state.setLocation);
  const resetLocationData = useStore((state) => state.resetLocationData);
  const locationData = useStore((state) => state.locationData);
  const setStreetNumber = useStore((state) => state.setStreetNumber);


  const map = useMap();
  const places = useMapsLibrary('places');

  const [value, setValue] = React.useState(null);
  const [autocompleteService, setAutocompleteService] =
    useState(null);
  const [sessionToken, setSessionToken] = useState(null);

  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const [placesService, setPlacesService] = useState(null);

  const [predictionResults, setPredictionResults] = useState([]);

  const [inputValue, setInputValue] = useState('');

  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    if (!places) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);

  }, [places, map]);



  const fetchPredictions = useCallback(
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        return;
      }

      setFetchingData(true);

      const request = { input: inputValue, sessionToken,componentRestrictions: { country: 'CH' }, // Restrict results to Switzerland
    };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
      setFetchingData(false);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (value) => {
      if (typeof value === 'string') {
        setInputValue(value);
        fetchPredictions(value);
      }
    },
    [fetchPredictions]
  );


  const onSelect = useCallback(
    (prediction) => {
      console.log('onSelect called with prediction:', prediction);

      if (!places || typeof prediction === 'string' || !prediction) return;

      setFetchingData(true);

      const detailRequestOptions = {
        placeId: prediction.place_id,
        fields: ['geometry', 'name', 'formatted_address', 'address_components'],
        sessionToken,
      };

      const detailsRequestCallback = async(placeDetails) => {
        console.log('detailsRequestCallback called with placeDetails:', placeDetails);

        setInputValue(placeDetails?.formatted_address ?? '');
        setSessionToken(new places.AutocompleteSessionToken());

        const zipCodeComponent = placeDetails.address_components.find((component) =>
          component.types.includes('postal_code')
        );

        const locationComponent = placeDetails.address_components.find((component) =>
          component.types.includes('locality')
        );


        const routeComponent = placeDetails.address_components.find((component) =>
          component.types.includes('route')
        );


        const streetNumberComponent = placeDetails.address_components.find((component) =>
          component.types.includes('street_number')
        );

        const zipCode = zipCodeComponent?.long_name ?? null;
        const location = locationComponent?.long_name ?? '';
        const street = routeComponent?.long_name ?? ''; //StreetName
        const number = streetNumberComponent?.long_name ?? '';

        const streetNumberFullName = street + " " + number;

        setZipcode(zipCode);
        setLocation(location);
        setStreetNumber(streetNumberFullName);

        const patchObject = {
          location: location,
          streetNumber: streetNumberFullName,
          zipCode: parseInt(zipCode)
        }

        await updateAdvertTwo(token,advertId,patchObject);

        
        setFetchingData(false);
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [places, placesService, sessionToken, inputValue]
  );


  return (
    <Autocomplete

      id="google-map-demo"
      sx={{
        width: "100%",
        "@media (min-width: 900px)": { width: "48.5%" },
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={predictionResults}
      autoComplete='ViewCrunch'
      includeInputInList
      filterSelectedOptions
      value={inputValue}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue.main_text, ...options] : options);
        setValue(newValue);
        onSelect(newValue)

      }}
      onInputChange={(event, newInputValue) => {
        if (!newInputValue) {
          console.log('reset data location');
          resetLocationData();

        }
        onInputChange(newInputValue)
      }}
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      renderInput={(params) => (
        <TextField autoComplete='ViewCrunch' {...params} label="Strasse & Nr." fullWidth />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
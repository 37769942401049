import React, {useState, useEffect, useCallback } from "react";
import useStore from "../../store";
import { updateAdvert, updateAdvertTwo } from "../../api";
import {APIProvider, Map, useMapsLibrary, Marker, APIProviderContext} from '@vis.gl/react-google-maps';
import { debounce } from 'lodash';
import {objectType, customerSegment} from '../../utilis/helper'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Sheet from '@mui/joy/Sheet';
import FormLabel from '@mui/joy/FormLabel';
import { Radio as RadioJoy, RadioGroup as RadioGroupJoy} from '@mui/joy';
import { radioClasses } from '@mui/joy/Radio';
import Box from '@mui/joy/Box';
import Tooltip from "@mui/material/Tooltip";
import { useFormContext } from "react-hook-form";
import { resultArray as resultArrayData } from "../../utilis/helper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const url = process.env.REACT_API_GOOGLE_MAPS;


const Geocoding = ({locationAdress, handleGeocoding}) => {
  const geocodingApiLoaded = useMapsLibrary("geocoding");
  const [geocodingService, setGeocodingService] = useState(null);
  const [geocodingResult, setGeocodingResult] = useState();

  const [address, setAddress] = useState("Rebenweg 40, 8400 Winterthur");

  useEffect(() => {
    if (!geocodingService || !address) return;

    geocodingService.geocode({ address }, (results, status) => {
      if (results && status === 'OK') {
        setAddress(locationAdress)
        handleGeocoding(results[0]);
      }
    });
  }, [geocodingService]);

  return null;
};


export const EditBasicFeatures = ({
  errorFields,
  stepIndicator,
  resetDateField,
  onDateChange,
  handleLocationCordinates
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext({
    mode: "onChange",
    defaultValues: {
      availability: "Nach Vereinbarung",
      customerType: 'owner'
    },
  });
  const [selectedDate, setDateSelected] = useState(false);
  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails)
  const [isAvailchoice, setAvailChoice] = useState(false);
  const [cleared, setCleared] = useState(false);
  const selectedDateInput = watch("Date");
  const availability = watch("availability");
  const offerType = watch("offerType");
  const date = useStore((state) => state.date);
  const setDate = useStore((state) => state.setDate);
  const customerType = useStore((state) => state.customerType);
  const advert = useStore((state) => state.advertDetails);
  const setCustomerType = useStore((state) => state.setCustomerType);
  const [locationGps, setLocationGps] = useState( {lat: 0 , lng:0});
  const [isSelected, setIsSelected] = useState(false);
  

  const street = watch("streetNumber");
  const location = watch("location");
  const zipcode = watch("zipCode");

  const completeAdress = `${advert}, ${location} ${zipcode}`;

  useEffect(() => {

    if (advert.lat && advert.lng) {
      const lat = parseFloat(advert.lat);
      const lng = parseFloat(advert.lng);
      if (!isNaN(lat) && !isNaN(lng)) {
        setLocationGps({ lat, lng });
      } else {
        console.error('Invalid latitude or longitude values.');
      }
    } else {
      console.warn('Latitude or longitude values are undefined.');
    }
    setValue("availability", availability);
    setValue("customerCategory", advert.customerCategory);
    setCustomerType(advert.customerCategory);
    setValue("offerType", advert.offerType);
  }, [setValue, advert.lat, advert.lng], advert);

  useEffect(() => {
    errorFields(errors);
    stepIndicator({ step: 1, errors: Object.keys(errors) });
    if (selectedDateInput) {
      setDateSelected(true);
    } else {
      setDateSelected(false);
    }
  }, [selectedDateInput, errors]);
  const resultArray = resultArrayData;

  const handleAvailabilityChange = (e, key) => {
    setValue("availability", e.target.value);
    setAvailChoice(true);
    updateAdvertDetails("availability", e.target.value);
    debouncedUpdateAdvert(token, advertId, key,  e.target.value);
  };


  const handleUpdate = useCallback((token, advertId, key, value) => {
    return updateAdvert(token, { id: advertId, key, value });
  }, []);

  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, key, value) => {
      handleUpdate(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );


  const handleChangeOverAll = (key) => async (event) => {
    const value = event.target.value;

    updateAdvertDetails(key, value);

    console.log(value)
    if (key === "location") {
      debouncedUpdateAdvert(token, advertId, key, value);

    } else if (key === "zipCode" || key === "roomSpace") {
      debouncedUpdateAdvert(token, advertId, key, parseInt(value));
    } else {
      // Update the form value
      setValue(key, value);
      debouncedUpdateAdvert(token, advertId, key, value);
    }
  };



  const handleDateChange = (newDate) => {
    //Prevent Executing if no Date has set

    console.log(newDate);

    if (!newDate) {
      setDateSelected(false);
      setDate("");
      return;
    }

    const formattedDate = dayjs(newDate.$d);
    setDate(formattedDate);
    setDateSelected(true);
    setValue("availability", "");
    setAvailChoice(false);
    onDateChange(formattedDate); // Notify parent component about date change
  };


  const handleRadioChange = (event, key) => {
    setIsSelected(true);
    const customerType = event.target.value;
    setCustomerType(customerType);
    debouncedUpdateAdvert(token, advertId, key, customerType);
  };



  const handleGeocoding = (geoData) => {
    if(geoData == null || geoData === undefined || geoData === '') return;

    const location = geoData.geometry.location;
    setLocationGps({lat: location.lat(), lng: location.lng()});
    handleLocationCordinates({lat: location.lat(), lng: location.lng()});
    debouncedUpdateAdvert(token, advertId, 'lat', location.lat().toString());
    debouncedUpdateAdvert(token, advertId, 'lng', location.lng().toString());
    
  };

  return (
    <>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
          marginBottom: "40px",
          height: '100%'
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
        Ich inseriere als:
        </h2>
        <Box sx={{ width: 300 }}>
      <FormLabel
        id="storage-label"
        sx={{
          mb: 2,
          fontWeight: 'xl',
          textTransform: 'uppercase',
          fontSize: 'xs',
          letterSpacing: '0.15rem',
        }}
      >
      </FormLabel>
      <RadioGroupJoy
        aria-labelledby="customer-label"
        onChange={(event) => handleRadioChange(event, 'customerCategory')}
        size="lg"
        sx={{ gap: 1.5,  flexDirection: 'row',  [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: '3px solid',
            borderColor: 'primary.500',
          },
        },
       }}
        
      >
        {customerSegment.map((value, index) => (
          <Sheet
            key={index}
            sx={{ p:2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 'md',
              boxShadow: 'sm',
              flexGrow: 1,
              backgroundColor: (value.value === advert.customerCategory && !isSelected) ? '#dde7ee' : 'transparent'
            }}
          
          >
            <RadioJoy
            {...register("customerCategory", { required: false })}
              label={value.label}
              overlay
              disableIcon
              value={value.value}
              error={Boolean(errors?.customerType)}
              
              slotProps={{
                label: ({ checked }) => ({
                  sx: {
                    fontWeight: 'lg',
                    fontSize: 'md',
                    color: 'black',
                  },
                }),
                action: ({ checked }) => ({
                  sx: (theme) => ({
                    ...(checked && {
                      '--variant-borderWidth': '2px',
                      '&&': {
                        // && to increase the specificity to win the base :hover styles
                        borderColor: theme.vars.palette.primary[500],
                      },
                    }),
                  }),
                }),
              }}
            />
          </Sheet>
        ))}
      </RadioGroupJoy>
    </Box>
      </FormControl>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
          marginBottom: "40px",
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
        Immobilie zu
        </h2>
        <RadioGroup
        defaultValue={advert.offerType}
          onChange={handleChangeOverAll("offerType")}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value={advert.offerType}
            control={
              <Radio {...register("offerType")} />
            }
            label="Vermieten"
          />
          <FormControlLabel
            value="buy"
            control={
              <Radio {...register("offerType")} />
            }
            label="Verkaufen"
          />
        </RadioGroup>
      </FormControl>
      <Stack 
        sx={{marginBottom: 4}}
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("objectType", {
            value: "Objekttyp",
            required: true,
          })}
          select
          
          value={advert.objectType}
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.5%" },
          }}
          id="outlined-required"
          label="Kategorie"
          onChange={handleChangeOverAll("objectType")}
        >
          {objectType.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Stack>
      <Stack
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("rooms", {
            value: "Anzahl Zimmer",
            required: true,
          })}
          onChange={handleChangeOverAll("rooms")}
          defaultValue={advert.rooms ?? '2.0'}
          select
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.5%" },
          }}
          id="outlined-required"
          label="Anzahl Zimmer"
          helperText="Bitte geben Anzahl zimmer an"
        >
          {resultArray.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          defaultValue={advert.roomSpace}

          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>m²</span>,
          }}
          {...register("roomspace", { required: true })}
          onChange={handleChangeOverAll("roomSpace")}
          id="outlined-required"
          type="number"
          label="Verfügbare Wohnfläche"
          helperText={
            Boolean(errors?.roomspace)
              ? "Angaben zur Wohnfläche sind Pflicht"
              : ""
          }
          error={Boolean(errors?.roomspace)}
        >
        </TextField>

        <TextField
          required
          defaultValue={advert.streetNumber}
          {...register("streetNuber", { required: true })}
          onChange={handleChangeOverAll("streetNuber")}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.5%" },
          }}
          id="outlined-required"
          type="text"
          label="Strasse & Nr."
          helperText={
            Boolean(errors?.street) ? "Strasse und Nr ist ein Pflichfeld" : ""
          }
          error={Boolean(errors?.street)}
        >
        </TextField>
        <TextField
          {...register("zipCode", { required: true })}
          required
          defaultValue={advert.zipCode}
          error={Boolean(errors?.zipCode)}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "23%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          id="outlined-required"
          type="number"
          label="PLZ "
          helperText={Boolean(errors?.zipcode) ? "Das ist ein Pflichtfeld" : ""}
        ></TextField>
        <Tooltip title="Blur is not triggered" placement="top" arrow>
          <TextField
          defaultValue={advert.location}
            {...register("location", { required: true })}
            error={Boolean(errors?.location)}
            required
            sx={{
              width: "100%",
              "@media (min-width: 900px)": { width: "22%" },
            }}
            id="outlined-required"
            label="Ort"
            helperText={Boolean(errors?.location) ?? ''}
          >
          </TextField>
        </Tooltip>
      </Stack>
      <APIProvider apiKey='AIzaSyBzp--bZvp1fRNbmIpVBhe-MaRh4qxOUkg'>
        <Geocoding handleGeocoding={handleGeocoding}  locationAdress={advert.streetNumber + " " + advert.zipCode + " " + advert.location} />
        <Map
          center={locationGps}
          style={{width: '100%', height: '180px', marginTop: '20px', marginBottom: '10px'}}
          defaultCenter={locationGps}
          defaultZoom={14}
          gestureHandling={'greedy'}
          disableDefaultUI={true} 
          >

          <Marker position={locationGps}/>
        </Map>
      </APIProvider>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
          Verfügbarkeit
        </h2>
        <RadioGroup
          defaultValue={advert.availability}
          onChange={(e) => handleAvailabilityChange(e, "availability")}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Sofort"
            control={
              <Radio {...register("availability")} disabled={selectedDate} />
            }
            label="Sofort"
          />
          <FormControlLabel
            value="Nach Vereinbarung"
            control={
              <Radio {...register("availability")} disabled={selectedDate} />
            }
            label="Nach Vereinbarung"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                    slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => {
                      setCleared(true);
                      setDate("");
                    },
                  },
                }}
                {...(date || isAvailchoice
                  ? { defaultValue: dayjs(date) }
                  : {})}
                label="Datum auswählen"
                format="D/MM/YYYY"
                onChange={handleDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </RadioGroup>
      </FormControl>
    </>
  );
};

import React, { useEffect } from "react";
import useStore from "../../store";
import { featuresAndAmenities } from '../../utilis/helper';

export const Summary = ({ advert, previewPictures }) => {
  const setPictures = useStore((state) => state.setPictures);
  const advertDetails = useStore((state) => state.advertDetails);


  useEffect(() => {
    setPictures(previewPictures);
  }, [previewPictures])

  if (!advert) {
    return;
  }

  const buildOfferType = (offerType) => {
    switch (offerType) {
      case 'rent':
        return "Vermieten";
      case 'buy':
        return "Verkaufen";
    }
  };


  const customerType = (type) => {
    switch (type) {
      case 'tenant':
        return "Mieter";
      case 'owner':
        return "Eigentümer";
      case 'professional':
        return "Firmenkunde";
    }
  };


  const objectType = (type) => {
    switch (type) {
      case 'appartment':
        return "Wohnung";
      case 'office':
        return "Büro";
      case 'house':
        return "Haus";
      case 'trades':
        return "Gewerbe";
      case 'parkingPlace':
        return "Parkplätze";
      case 'buildingPlot':
        return "Bauland";
    }
  }


  const combinedFeatures = advert.featuresAndAmenities
    .filter(item => item.value === true);
  console.log(combinedFeatures);


  return (
    <div>
      {JSON.stringify(advertDetails)}
    </div>
  );
};

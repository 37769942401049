import React from "react";

import useStore from '../store';
import { useAuth0 } from "@auth0/auth0-react";



export default function Home() {

  const currentUser = useStore(state => state.user);

  return (
      <div>
       Hallo
      </div>
    )

}

// store.js
import {create} from 'zustand';
import {devtools} from 'zustand/middleware'

const useStore = create()(devtools(
    (set) => ({
        user: {},
        pictures: [], 
        selectedFiles:[],
        locationData: {
          streetNumber: '',
          zipcode: null,
          location: ''
        },
        features:[],
        ads: [],
        customerType: '',
        videos: {},
        objectType: '',
        advertId: '',
        jwtToken: '',
        advertInfo: {},
        advertDetails: {},
        isAuthenticated: false,
        setVideos: (newVideos) => set((state) => ({
          videos: newVideos
        })),

        updateVideos: (newVideo) => set((state) => ({
          videos: [...newVideo]  // Overwrite with new pictures
        })),
    
        setResetedPictures: (newPictures) => set((state) => ({
          pictures: [...newPictures]  // Overwrite with new pictures
        })),
        setPictures: (newPictures) => set((state) => {
          // Ensure newPictures is an array
          if (!Array.isArray(newPictures)) {
            return state;
          }
      
          // Create a new array with updated pictures
          const updatedPictures = state.pictures.map(pic => {
            // Find if the picture in the state needs to be updated
            const update = newPictures.find(newPic => newPic.id === pic.id);
            return update ? { ...pic, ...update } : pic;
          });
      
          // Add any new pictures that don't exist in the current state
          const uniqueNewPictures = newPictures.filter(newPic =>
            !state.pictures.some(existingPic => existingPic.id === newPic.id)
          );
      
          return { pictures: [...updatedPictures, ...uniqueNewPictures] };
        }),

        
        removePictures: (id) =>
          set((state) => ({
            pictures: state.pictures.filter((image) => image.id !== id),
          })),
        clearPictures: () => set({ pictures: [] }),
        setDate: (newDate) => set({ date: newDate }),
        setUser: (signedUser => set({user: signedUser})),
        setAdvertId: (id => set({advertId: id})),
        setCustomerType: (customer => set({customerType: customer})),
        setObjectType: (category => set({objectType: category})),
        setAdvertInfo: (advert => set({advertInfo: advert})),
        setRooms: (room => set({rooms: room})),
        setStage: (etage => set({stage: etage})),
        setJwtToken: (token => set({jwtToken:  token})),
        setAds: (ad => set({ads:  ad})),
        setSelectedFiles: (files => set({selectedFiles:  files})),
        setAdvertDetails: (data => set({advertDetails:  data})),
        setIsAuthenticated: (data => set({isAuthenticated:  data})),
        setAdvertFeatures: (data => set({features:  data})),
        updateFeature: (label, value) => {
            set((state) => ({
              advertDetails: {
                ...state.advertDetails,
                featuresAndAmenities: state.advertDetails.featuresAndAmenities.map((feature) =>
                  feature.label === label ? { ...feature, value } : feature
                ),
              },
            }));
          },
          clearFeatures: () => set({ features: [] }),

          updateAdvertDetails: (key, value) => set((state) => ({
            advertDetails: {
              ...state.advertDetails,
              [key]: value,
            },
          })),

          removeImage: (id) => set((state) => ({
            advertDetails: {
              ...state.advertDetails,
              images: state.advertDetails.images.filter((image) => image.id !== id),
            },
          })),
          updateImage: (id, newImageData) => set((state) => ({
            advertDetails: {
              ...state.advertDetails,
              images: state.advertDetails.images.map((image) => 
                image.id === id ? { ...image, ...newImageData } : image
              ),
            },
          })),
          setStreetNumber: (streetNumber) => set((state) => ({
            locationData: {
              ...state.locationData,
              streetNumber
            }
          })),
          setZipcode: (zipcode) => set((state) => ({
            locationData: {
              ...state.locationData,
              zipcode
            }
          })),
          setLocation: (location) => set((state) => ({
            locationData: {
              ...state.locationData,
              location
            }
          })),
          resetLocationData: () => set({
            locationData: {
              streetNumber: '',
              zipcode: null,
              location: ''
            }

          }),
      
     
    })
));
export default useStore;
import { useState, useCallback, useEffect } from 'react';

export const useRequestQueue = () => {
  const [queue, setQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const processQueue = useCallback(async () => {
    if (isProcessing || queue.length === 0) return;
    setIsProcessing(true);

    const [currentRequest, ...remainingQueue] = queue;
    setQueue(remainingQueue);

    try {
      await currentRequest();
    } catch (error) {
      console.error('Request failed', error);
    } finally {
      setIsProcessing(false);
    }
  }, [isProcessing, queue]);

  useEffect(() => {
    if (!isProcessing && queue.length > 0) {
      processQueue();
    }
  }, [isProcessing, queue, processQueue]);

  const addToQueue = useCallback(
    (requestFn) => {
      setQueue((prevQueue) => [...prevQueue, requestFn]);
    },
    []
  );

  return addToQueue;
};

export default useRequestQueue;

import React, { useEffect, useRef, useCallback } from "react";
import useStore from "../../store";
import { updateAdvert } from "../../api";
import { debounce } from 'lodash';
import { Stack, TextField, FormHelperText } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const EditContactDetails = ({errorFields}) => {
  const { register, formState, setValue } = useFormContext();
  const { errors } = formState;
  const emailInputRef = useRef(null);
  const advert = useStore((state) => state.advertDetails);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails)
  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);


  const handleUpdate = useCallback((token, advertId, key, value) => {
    return updateAdvert(token, { id: advertId, key, value });
  }, []);

  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, key, value) => {
      handleUpdate(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );

  const handleChangeOverAll = (key) => async(event) => {
    const value = event.target.value;
    // Update the form value
    setValue(key, value);
    updateAdvertDetails(key, value)
    // Call the debounced function
    debouncedUpdateAdvert(token, advertId,key, value)
  };




  useEffect(() => {
    errorFields(errors);
  }, [errors])

  
  return (
    <div>
      <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
        Kontakt
      </h2>

      <Stack
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("fullName", {required: true})}
          onChange={handleChangeOverAll("fullName")}
          required
          defaultValue={advert.fullName}
          error={Boolean(errors?.fullName)}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.2%" },
          }}
          id="outlined-required"
          label="Vor & Nachname"
        ></TextField>

        <TextField
          {...register("phoneNumber")}
          onChange={handleChangeOverAll("phoneNumber")}
          type="number"
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          id="outlined-required"
          label="Tel"
          helperText="Bitte geben sie eine gütlige Telefonnummer"
        ></TextField>

        <div className="flex flex-col w-[100%]">
          <TextField
            type="email"
            ref={emailInputRef}
            defaultValue={advert.email}
            error={Boolean(errors?.email)}
            onChange={handleChangeOverAll("email")}
            required
            sx={{
              width: "100%",
              "@media (min-width: 900px)": { width: "48.2%" },
            }}
            id="outlined-required"
            label="Email"
            inputProps={{
              ...register("email", {
                required: "Email Adresse Field",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                  message: "Gebe eine gültige Email Address ein",
                },
              }),
            }}
          ></TextField>
          {errors.email && (
            <FormHelperText sx={{ mt: 1 }}>
              {errors.email.message}
            </FormHelperText>
          )}
        </div>
      </Stack>
    </div>
  );
};

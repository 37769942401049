import React, { useEffect, useRef, useState , useReducer} from "react";
import axios from "axios";
import { handelFileUpload, getSingleAdvert, deleteImage, handleFileUpdate, deleteVideo, handleVideoFileUpload, findAdvertImages, createSlideshow } from '../../api';
import ConfirmDialog from "../ConfirmDialog";
import {VideoUploadModal} from '../VideoUploadModal';
import SlideshowIcon from '@mui/icons-material/Slideshow'; // Import the slideshow icon from MUI



import useStore from "../../store";
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import 'lightbox.js-react/dist/index.css'
import { SlideshowLightbox, initLightboxJS } from 'lightbox.js-react';
import { Image } from "lightbox.js-react";
import { ReactComponent as AddImage } from "../../assets/add-image.svg";
import { ReactComponent as AddVideo } from "../../assets/add-video.svg";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";



const url = "http://localhost:5000";



const VideoPlayer = ({ videoData, url, onDelete }) => {

  let modifiedUrl;
  if(videoData.videoUrl) {
    modifiedUrl = videoData.videoUrl.startsWith('https://')
    ? videoData.videoUrl.replace('https://', '')
    : videoData.videoUrl;

  }


  console.log(videoData, 'props video data')
  return (
    <div
    className="border md:w-[25%] h-[250px] rounded-lg relative border-gray-300 cursor-pointer overflow-hidden"
  >
   <video className="video-player" controls height="100%" width="100%">
        <source src={'https://' + modifiedUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    <div
      onClick={(e) => {
        e.stopPropagation();
        onDelete(videoData.id, videoData.filename)
       
      }}
      className="absolute top-1.5 right-1.5 rounded-full p-1.5 bg-blue-600 flex h-[30px] w-[30px] justify-center items-center"
    >
      <Trash fill="white" />
    </div>

    <div
      onClick={(e) => {
        e.stopPropagation();
        
      }}
      className="absolute top-1.5 right-10 rounded-full p-1.5 bg-blue-600 flex h-[30px] w-[30px] justify-center items-center"
    >
      <Edit fill="white" />
    </div>
  </div>
  );
};


export const EditImagesVideo = () => {

  const [, forceUpdate] = useReducer(x => x + 1, 0);


  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const pictures = useStore(state => state.pictures);
  const setPictures = useStore((state) => state.setPictures);
  const token = useStore(state => state.jwtToken);
  const [loading, setLoading] = useState(false);
  const [filesTemporary, setFilesTemporary] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [currentVideoId, setCurreentVideoId] = useState(null);
  const [currentFilename, setCurrentFilename] = useState(null);
  const setVideos = useStore((state) => state.setVideos);
  const [forceRender, setForceRender] = useState(false);
  const clearPictures = useStore((state) => state.clearPictures);
  const setResetedPictures = useStore(state => state.setResetedPictures);
  const advertId = useStore(state => state.advertId);
  const videos = useStore((state) => state.videos);

  const fileInputRef = useRef(null);
  const fileInputEditRefs = useRef([]);
  const videoInputRef = useRef(null);
  const [isPictures, setIsPictures] = useState(false);
  const advert = useStore((state) => state.advertDetails);
  const setCurrentInsertion = useStore(state => state.setAdvertDetails);
  const removeImage = useStore((state) => state.removeImage);
  const [videoDialog, setVideoDialog] = useState(false);

  const user = useStore((state) => state.user);
  const [video, setVideoUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);



  useEffect(() => {
    clearPictures();
    setPictures(advert.images);
    setVideos(advert.video);
    initLightboxJS("215B-24A9-4454-0696", "Individual");
  }, [advert]);

  

  const handleFileChange = async (e, editIndex) => {
    
    const selectedFiles = e.target.files;
    const arrayOfObjects = Object.values(e.target.files);

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }
    setLoading(true);

    // Filter out duplicates
    const existingFilenames = filesTemporary.map(file => file.name);
    const newFiles = arrayOfObjects.filter(file => !existingFilenames.includes(file.name));

    if (newFiles.length === 0) {
      return;
    };

    if (advert.id) {
      try {
        const response = await handelFileUpload(formData, advert.id);
        if (response.data.statusCode >= 200) {
          const updatedAdvert = await getSingleAdvert(token.access_token, advert.id);
          setCurrentInsertion(updatedAdvert); // Update Zustand store with new data
          setPictures(updatedAdvert.images)
          
          setOpen(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error creating advertisement:", error);
      } 
    }

    setIsPictures(true);
    
  };



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const hanndleEditChange =  async(e, index) => {
    if(!e || !e.target || !e.target.files) {
      return;
    };

    // Access files
  const selectedFiles = e.target.files;
  if (selectedFiles !== null || selectedFiles !== undefined) {

    const pictureToUpdate = pictures[index];
    console.log(pictureToUpdate, 'selecetd pic')
    console.log('Files to process:', selectedFiles[0]);

    const formData = new FormData();
    formData.append('imageId', pictureToUpdate.id)
    formData.append('advertId', advert.id);
    formData.append('file', selectedFiles[0]);

    setLoading(true);

    if (advert.id) {
      try {
        const response = await handleFileUpdate(token,formData);
        console.log("Upload updated successfully", response);

        if (response.data.statusCode >= 200 && response.data.statusCode <=300) {
          const updatedImage = response.data.updatedImage;
          const updatedAdvert = await getSingleAdvert(token.access_token, advert.id);

          setCurrentInsertion(updatedAdvert)
        

          setPictures(prevPictures => {
            const newPictures = [...prevPictures];
            console.log(newPictures, 'here')
            newPictures[index] = { ...newPictures[index], imageUrl: updatedImage.imageUrl };
            return newPictures;
          });

          setForceRender(prev => !prev);
          setLoading(false);
        

        }
      } catch (error) {
        console.error("Error updating picture:", error);
      }
    }
  }
  };



  const handleVideoChange = (e) => {
    const videoFile = e.target.files[0];

    if (!videoFile.type.startsWith("video/")) {
      alert("is not a Video Format please celect vidoe file");
      return;
    }

    const formData = new FormData();

    formData.append("files[]", videoFile);

    setLoading(true); 

    handleVideoFileUpload(formData, advertId)
      .then(async (response) => {
        console.log("Upload created successfully", response);
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          setVideos(null); // Temporarily clear video data
          setTimeout(() => {
            setVideos({ ...response.data.videos[0] }); // Set new video data
            setForceRender(prev => !prev);
          }, 0); // Slight delay to ensure re-render
        }
      })
      .catch((error) => {
        console.error("Error creating advertisement:", error);
      })
      .finally(() => {
        setLoading(false);
      })

  };


  const handleDeleteImage = async (id, filename) => {
    setCurrentImageId(id);
    setCurrentFilename(filename);
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setConfirmDelete(false); // Reset confirmDelete state
  };

  const handleDeleteVideo = async (id, filename) => {
    setCurreentVideoId(id);
    setVideoDialog(true);
    handleOpenDialog();
  };

  const handleConfirmDialog = async () => {
    setConfirmDelete(true);
    const updatedAdvert = await getSingleAdvert(token.access_token, advert.id);

    if(videoDialog) {
      await deleteVideo(currentVideoId);
      setVideos(updatedAdvert.data);
    }
   
    await deleteImage(currentImageId); // Make sure deleteImage is async
    const updatedAdvertPostDelete = await getSingleAdvert(token.access_token, advert.id);
    console.log(updatedAdvertPostDelete.images)
    setResetedPictures(updatedAdvertPostDelete.images);
    setForceRender(prev => !prev);
    handleCloseDialog();
  };

  const generateSlideShow = async() => {
    const all = [advert, pictures];

    setLoading(true);

    createSlideshow(all, user)
      .then(async (response) => {
        if (response.statusCode >= 200 && response.statusCode < 300) {
          setVideos(null); // Temporarily clear video data
          setTimeout(() => {
            setVideos({ ...response.videos }); // Set new video data
            setForceRender(prev => !prev);
          }, 0); // Slight delay to ensure re-render
        }
      })
      .catch((error) => {
        console.error("Error creating advertisement:", error);
      })
      .finally(() => {
        setLoading(false);
      })

  }


  


  return (
    <div>
      <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
        Bilder & Dokumente
      </h2>
      <div className="flex gap-5 flex-wrap mb-6">
   
        <>
          {pictures.map((previewImage, index) => (

            <>

                <input
                type="file"
                name="edit"
                ref={el => fileInputEditRefs.current[index] = el}
                style={{ display: "none" }}
                onChange={(e) => hanndleEditChange(e, index)} // Bind onChange correctly
              />
              <div
                key={index}
                className="border w-[100%] sm:w-[185px] h-[158px] rounded-lg relative border-gray-300 cursor-pointer overflow-hidden"
                onClick={() => {
                  setCurrentIndex(index);
                  setOpen(true);
                }}
              >
                <Image key={forceRender} downloadImages={true}  lightboxImgClass={"lightboxImg"} image={{ src: `https://${previewImage.imageUrl}`, title: "Cyberpunk" }} />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(previewImage.id, previewImage.filename);
                  }}
                  className="absolute top-1.5 right-1.5 rounded-full p-1.5 bg-blue-600 flex h-[30px] w-[30px] justify-center items-center"
                >
                  <Trash fill="white" />
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    hanndleEditChange(index);
                    fileInputEditRefs.current[index].click()
                  }}
                  className="absolute top-1.5 right-10 rounded-full p-1.5 bg-blue-600 flex h-[30px] w-[30px] justify-center items-center"
                >
                  <Edit fill="white" />
                </div>
              </div>
            </>
          ))}
        </>
      </div>

      <input
        type="file"
        name="image"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple // Erlaube das Hochladen mehrerer Dateien
      />

      <input
        type="file"
        name="video"
        ref={videoInputRef}
        style={{ display: "none" }}
        onChange={handleVideoChange}
      />

      <div className="flex flex-wrap gap-5 bg-white">
        <div
          className="rounded border-2 border-dotted border-indigo-600 bg-[#cfd6e280] flex-1 flex justify-center items-center cursor-pointer min-h-40 "
          onClick={() => fileInputRef.current.click()}
        >
          {isPictures ? (
            <div className="flex-col justify-center flex">
              <p className="font-bold text-lg">Weitere bilder hinzufügen</p>
              <AddImage height={110} />
            </div>
          ) : (
            <AddImage height={110} />
          )}
        </div>

        <div
          className=" rounded-md border-2 border-dotted border-indigo-600 bg-[#cfd6e280] flex flex-1 justify-center items-center min-h-40 cursor-pointer"
          onClick={() => videoInputRef.current.click()}
        >
          <AddVideo height={110} />
        </div>

        <button
      type="button"
      onClick={generateSlideShow}
      className="w-full flex items-center bg-[#3b82f6] text-white rounded-lg px-2 py-2 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#020d1f] transition duration-300 ease-in-out"
    >
            <SlideshowIcon className="mr-2" style={{ fontSize: 35 }} /> {/* Adjust size as needed */}
      Slideshow Generieren
    </button>

        <div className="block  flex-1">

          <button
            type="button"
            onClick={handleOpenModal}
            className="w-[100%] rounded-md border-2 border-dotted border-indigo-600 bg-[#cfd6e280] flex justify-center items-center min-h-40 cursor-pointer"
          >
            <AddVideo height={110} />
          </button>


        <VideoUploadModal
          open={modalOpen}
          onClose={handleCloseModal}
          onUpload={handleVideoChange}
        />

      </div>

      </div>

      <div className="my-8">
        {
          loading && (
            <LinearProgress />
          )
        }
      </div>

      <div>
        {videos && <VideoPlayer onDelete={handleDeleteVideo} videoData={videos} url={video} />}
      </div>

      <Snackbar
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          autoHideDuration={2000}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Die Datei wurde erfolgreich hochgeladen
        </Alert>
      </Snackbar>

      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
      />
    </div>
  );
};

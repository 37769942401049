import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from "@mui/material/CircularProgress";

export const Login =() => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();  // Redirects to the Auth0 login page
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <div className='h-full'>
         <CircularProgress />
    </div>
  );
}


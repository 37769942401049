import React, { useState, useEffect } from "react";
import useStore from "../../store";
import { FormControlLabel, MenuItem, Stack, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Controller, useFormContext } from "react-hook-form";
import { defaultFeatures, stages as stage } from "../../utilis/helper";
import FeatureCheckboxGroup from "../FeatureCheckboxGroup";

export const EditFeatFeatures = ({handleFeatures}) => {
  const { register, control } = useFormContext();
  const stages = stage;
  const [loading, setLoading] = useState(true);
  const advert = useStore((state) => state.advertDetails);
  const features = useStore((state) => state.features);
  const setFeatures = useStore((state) => state.setAdvertFeatures);
  const clearFeatures = useStore((state) => state.clearFeatures)
  const updateFeature = useStore((state) => state.updateFeature);
  const [localFeatures, setLocalFeatures] = useState(defaultFeatures);


    useEffect(() => {
        setLoading(false);
      }, [advert.featuresAndAmenities, setFeatures]);


      const handleChange = (_, feature) => (e) => {
        const newValue = e.target.checked;
        updateFeature(feature.label, newValue);
      };

  
  return (
    <>

      <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
        Details
      </h2>
      <Stack
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("stage")}
          select
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "49.2%" },
          }}
          id="outlined-required"
          defaultValue={advert.stage}
          label="Etage"
        >
          {stages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
            defaultValue={advert.buildYear}
          required
          {...register("buildYear")}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>Jahr</span>,
          }}
          id="outlined-required"
          type="number"
          label="Baujahr"
          helperText="Jahr eingeben in JJJJ - Format"
        ></TextField>

        <TextField
          required
          defaultValue={advert.renovation}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>Jahr</span>,
          }}
          {...register("renovation")}
          id="outlined-required"
          type="number"
          label="Letzte Renovation"
          helperText="Jahr eingeben in JJJJ - Format"
        ></TextField>
      </Stack>
      <div className="mt-10">
        <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
          Merkmale & Austattungen
        </h2>
      </div>
      <Box sx={{ marginBottom: "40px", display: 'flex' }}>
      {loading ? (
          <CircularProgress />
        ) : (
          <FeatureCheckboxGroup
            features={advert.featuresAndAmenities}
            handleChange={handleChange}
          />
        )}
      </Box>
    </>
  );
};

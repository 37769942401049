import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken, getTokenFromUuid, getUsersAds  } from "../src/api";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Route, Routes } from "react-router-dom";
import { getUser, handleRegistration } from "../src/api";
import "./App.css";
import { Header } from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Insertion from "./pages/Insertion";
import useStore from './store';
import AdvertDetails from "./pages/AdvertDetails";
import { Login } from "./pages/Login";
import InsertionWithUuid from "./pages/InsertionWithUuid";

const url = process.env.REACT_APP_API_URL;

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, user, logout } = useAuth0();
  const setUser = useStore(state => state.setUser);
  const signedUser = useStore(state => state.user);
  const setJwtToken = useStore(state => state.setJwtToken);
  const jwtToken = useStore(state => state.jwtToken);
  const setIsAuthenticated = useStore(state => state.setIsAuthenticated);
  const [urlUuid, setUuuid] = useState(false);

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");

  const navigate = useNavigate();

  useEffect(() => {
    if (uuid) {
      console.log(uuid, 'uuid here');

      let isUserExist;
      let subAuth;
  
      const uuidCheck = async () => {
        try {
          console.log(uuid, 'inside scope')
          getTokenFromUuid(uuid).then (data => {
            if(!data) {
              navigate('/login')
            };

            if(data.access_token) {
              userCheck();
              getUser(data.sub).then(user =>  setUser(user));
              setJwtToken(data.access_token)
              console.log(data, 'logedin');
              navigate('/create')
            }
          });
  
        } catch (error) {
          console.error("Error during UUID check:", error);
          // Handle error (e.g., redirect to login)
        }
      };
  
      const userCheck = async () => {
        try {
          const response = await axios.post(
            `${url}/user/check_sub`,
            { subAuth }, // Assuming user.sub is the value you want to pass
            {
              headers: {
                "Content-Type": "application/json",
              },
              timeout: 2000,
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error checking subscription status:", error);
          throw error;
        }
      };

  
      uuidCheck();
    }
  }, [uuid, url]);
  

  useEffect(() => {
    if (isAuthenticated) {

      setIsAuthenticated(isAuthenticated);

    
      const initToken =  async () => {
        try {
         const token  = await getAccessToken();
         setJwtToken(token.access_token);
        } catch (error) {
          
        }
      }

      const { sub, nickname, email } = user; //sub is the ID in the Auth0 system thorugh this we can identify the user
      if(!sub) {
        return;
      }
      const userCheck = async () => {
        try {
          const response = await axios.post(
            `${url}/user/check_sub`,
            { sub }, // Assuming user.sub is the value you want to pass
            {
              headers: {
                "Content-Type": "application/json",
              },
              timeout: 2000,
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error checking subscription status:", error);
          throw error;
        }
      };

      const checkUserBySub = userCheck(); // Call the function
      getUser(sub).then(user => setUser(user));
      checkUserBySub.then((data) => {
        if (!data) {
          handleRegistration(nickname, email, sub);
        }
      });
      
      if(!jwtToken) {
        initToken();
      }

    }
  }, [isAuthenticated, user]);

  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/insertion" element={<Insertion />} />
        <Route path="/insertion/:id" element={<AdvertDetails />} />
        <Route path="/insertion/profile" element={<AdvertDetails />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create" element={<InsertionWithUuid />} />
        <Route path="/login" element={<Login/>} />
        
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import useStore from '../store';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { getAccessTokenFromCode, getSingleAdvert } from '../api';
import { format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { featuresAndAmenities } from '../utilis/helper'
import Card from "@mui/material/Card";
import { FormProvider, useForm } from "react-hook-form";
import { Summary } from "../components/forms/Summary";
import "../../src/App.css";
import { EditBasicFeatures } from '../components/edit/EditBeasicFeatures'
import { EditCostDetails } from "../components/edit/EditCostDetails";
import { EditFeatFeatures } from "../components/edit/EditFeatFeatures";
import { EditContactDetails } from "../components/edit/EditContactDetails";
import { EditImagesVideo } from "../components/edit/EditImagesVideo";
import { EditDescription } from "../components/edit/EditDescription";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createAdvert, getAccessToken, handelFileUpload } from "../api";
import { v4 as uuidv4 } from 'uuid';


const AdvertDetails = () => {
  const { id } = useParams();
  const token = useStore(state => state.jwtToken);
  const setAdvId = useStore(state => state.setAdvertId);
  const [ad, setAd] = useState(null);

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();
  const methods = useForm();

  setAdvId(id);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedPictures, setSelectedPictures] = useState([]);
  const [step, setStep] = useState(1);
  const [locationGps, setLocationGps] = useState({ lat: null, lng: null });
  const [stepIndicator, setStepIndicator] = useState({});
  const [isGenSlideshow, setIsGenSlideshow] = useState(false);
  const date = useStore(state => state.date);
  const setDate = useStore(state => state.setDate);
  const setAdvertinfo = useStore(state => state.setAdvertInfo);
  const currentUser = useStore(state => state.user);
  const currentInsertion = useStore(state => state.advertDetails);
  const setCurrentInsertion = useStore(state => state.setAdvertDetails);
  const setFeaturess = useStore((state) => state.setAdvertFeatures);
  const [advertId, setAdvertId] = useState("");
  const [baseObject, setBaseObject] = useState({});
  const [features, setFeatures] = useState([]);
  const [open, setOpen] = useState(false);
  const [genId, setId] = useState('');
  const [loading, setLoading] = useState(true);


  const uuidFromUuidV4 = () => {
    const newUuid = uuidv4()
    setId(newUuid)
  }

  useEffect(() => {
    setAdvId(id); // Set advert ID when component mounts or ID changes
    console.log('here')
  }, [id, setAdvId]);

  useEffect(() => {
    uuidFromUuidV4();
    window.scrollTo(0, 0);
  }, [step]);



  useEffect(() => {
    const getSingleAds = async () => {
      const advert = await getSingleAdvert(token.access_token, id);
      console.log(advert, 'current advert');
      setCurrentInsertion(advert);
      setLoading(false);

    };

    getSingleAds();

  }, [id]);


  const onSubmit = async (d) => {

    const errorTrackingObject = Object.keys(errors);

    console.log(selectedFiles)

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }


    const dobject = d;


    const basics = {
      advert_UID: genId,
      userId: currentUser.id,
      advertId: genId,
      rooms: d.rooms,
      adDescription: d.adDescription,
      addTitle: d.adTitle,
      roomSpace: parseInt(d.roomspace),
      street_number: d.street,
      zipcode: parseInt(d.zipcode),
      lat: locationGps.lat,
      lng: locationGps.lng,
      location: d.location,
      availability: date === '' ? d.availability : date,
      rent: parseInt(d.rent),
      etage: d.stage,
      buildYear: parseInt(d.buildYear),
      renovation: parseInt(d.renovation),
      featuresAndAmenities: features,
      contact: {
        fullName: d.fullName,
        tel: d.phoneNumber,
        email: d.email

      },
      offerType: d.offerType,
      objectType: d.objectType,
      customerCategory: d.customerCategory
    };

    setAdvertinfo(basics);
    setBaseObject(basics);

    console.log(basics)


    // check if the Array is empty it means has no errors
    if (errorTrackingObject.length === 0 || step === 2) {
      setStep((prevStep) => prevStep + 1);
    }

    if (step === 5) {
      console.log('send the form');

      createAdvert(basics)
        .then((response) => {
          console.log("Advertisement created successfully:", response);
          console.log("Advertisement created successfully:", response.id);
          setAdvertId(response.id);

          if (response.id) {
            handelFileUpload(formData, response.id)
              .then((response) => {
                console.log("Upload created successfully", response);
              })
              .catch((error) => {
                console.error("Error creating advertisement:", error);
              });
          }
          // Handle success, e.g., show a success message to the user
          setOpen(true);
        })
        .catch((error) => {
          console.error("Error creating advertisement:", error);
          // Handle error, e.g., show an error message to the user
        })

    }


  };
  const handleFilesSelected = (files) => {
    setSelectedFiles(files);
  };


  const handlegeneratingSlideshow = (gen) => {
    setIsGenSlideshow(gen);

  };

  const handlePicturesSelected = (image) => {
    setSelectedPictures(image);
  };

  const handleLocationCordinates = (cords) => {
    setLocationGps(cords);
  };

  const handleFeatures = (features) => {
    setFeatures(features);
  };




  const resetDateField = (value) => {
    if (value === 'Nach Vereinbarung' || value === 'Sofort') {
      setDate('');
    }
  };


  const handleErrors = (error) => {
    console.log(error)
    if (error) {
      console.log(error)
      setErrors(error);
    }
  };

  const stepTracker = (step) => {
    setStepIndicator(step);
  }



  const handleDateSelection = (date) => {
    if (date && date.$d) {
      const dateFns = new Date(date.$d);
      const result = format(dateFns, 'yyyy-MM-dd') //The ISO 8601 format (YYYY-MM-DD)
      setDate(result);

    }
  };


  const handleNext = (e) => {
    console.log(errors)
    const customerCategoryRequired = errors.hasOwnProperty('customerCategory');
    if (customerCategoryRequired) {
      alert('Wähle eine CustomerCategroy an')
    }
    if (errors) {
      return;
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <EditBasicFeatures handleLocationCordinates={handleLocationCordinates} dateValue={date} resetDateField={resetDateField} stepIndicator={stepTracker} errorFields={handleErrors} onDateChange={handleDateSelection} />
            <EditCostDetails errorFields={handleErrors} />
          </>
        );
      case 2:
        return <EditFeatFeatures features={currentInsertion.featuresAndAmenities} handleFeatures={handleFeatures} errorFields={handleErrors} />;
      case 3:
        return (
          <>
            <EditImagesVideo adUID={genId} generatingSlideshowMode={handlegeneratingSlideshow} previewPictures={selectedPictures} onSelectedPictures={handlePicturesSelected} onFilesSelected={handleFilesSelected} />
            <EditDescription errorFields={handleErrors} />
          </>
        );
      case 4:
        return <EditContactDetails errorFields={handleErrors} />;
      case 5:
      // return <Summary previewPictures={selectedPictures}  advert={baseObject} />;

      default:
        return null;
    }
  };

  const indicatorTitle = ["Basis Informationen", "Merkmale & Details", "Bilder, Dokumente, Videos", "Kontakt"]

  const renderIndicators = () => {
    const indicators = [];
    for (let i = 0; i <= indicatorTitle.length - 1; i++) {
      const isActive = i + 1 === step;

      indicators.push(
        <div
          key={i}
          className={`flex items-center justify-center font-bold text-sm sm:text-base md:text-lg lg:text-xl border rounded px-5 py-4 border-gray-300 ${isActive
              ? "bg-gradient-to-r from-blue-400 to-pink-500 text-white"
              : "bg-gray-100"
            }`}
          onClick={() => {
            setStep(i + 1);
          }} // Set step on click
          style={{ cursor: 'pointer' }} // Add pointer cursor for better UX
        >
          <p className='text-xs sm:text-sm'>{indicatorTitle[i]}</p>
        </div>
      );
    }
    return indicators;
  };

  const IndicatorContainer = () => (
    <div className="flex gap-2 overflow-x-auto p-2 w-full mb-5">
      {renderIndicators().map((indicator, index) => (
        <div key={index} className="flex-shrink-0 w-1/2 sm:w-1/4 lg:w-auto">
          {indicator}
        </div>
      ))}
    </div>
  );




  return (
    <div className="bg-[#f7f6f5] h-screen">
      <div className="container mx-auto max-w-4xl">
        {isAuthenticated ? (
          <>
            <div className="p-2 sm:p-4">
              <div className="flex justify-between items-baseline">
                <h1 className="text-4xl font-bold text-left mt-10 mb-10 sm:mb-10 mt-10">
                  Inserat bearbeiten
                </h1>
              </div>
              <div className="">{IndicatorContainer()}</div>
              <div className="Form Wrapper flex-row">
                <FormProvider {...methods}>
                  <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                    <Card variant="outlined"

                      sx={{
                        width: "100%",
                        padding: 2, // Default padding for smaller screens
                        "@media (min-width: 900px)": { padding: 5 }, // Padding for screens wider than 900px
                      }}>

                      <div>
                        {loading ? (
                          <div className="h-full">
                            <CircularProgress />
                          </div>
                        ) : (
                          <>
                            {renderStep()}

                          </>
                        )}
                      </div>

                    </Card>
                    <div className="flex gap-4 justify-center sticky bottom-0 bg-white z-50 p-4">
                      {step > 1 && (
                        <button
                          className="border-2 border-indigo-300 rounded-md border-color px-4 py-2 text-gray mt-4 font-bold min-w-36 text-1xl"
                          onClick={handleBack}
                          type='button'
                        >
                          Zurück
                        </button>
                      )}
                      {(step <= 4) && (
                        <button
                          className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 px-4 py-2 text-white mt-4 font-bold min-w-36 text-1xl"
                          onClick={(e) => handleNext(e)}
                        >
                          Weiter
                        </button>
                      )}
                      {step === 5 && (
                        <button
                          className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 px-4 py-2 text-white mt-4 font-bold min-w-36 text-1xl"
                          type="submit"
                        >
                          Inserat erstellen
                        </button>
                      )}
                    </div>
                  </form>
                </FormProvider>
                <Snackbar
                  open={open}
                >
                  <Alert
                    autoHideDuration={2000}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    Das Inserat wurde erfolgreich erstellt
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <div className="h-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="p-4">
                <div>
                  <h4 className="font-bold text-2xl"> Wohnung inserieren</h4>
                  <ul>
                    <li>
                      Erstellen sie ihr Video Inserat mit automatisierten
                      beschreibung mit Ton
                    </li>
                    <li>Sehen Sie alle Bewerbungen auf einen Blick</li>
                    <li>Organisieren Sie Ihre Besichtigungstermine</li>
                  </ul>
                </div>
                <button
                  className=" rounded-md bg-gradient-to-r from-blue-400 to-pink-500 p-4 text-white mt-4"
                  onClick={loginWithRedirect}
                >
                  Anmelden
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default AdvertDetails
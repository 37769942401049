import React, {useCallback} from "react";
import useStore from "../../store";
import { useFormContext } from "react-hook-form";
import { updateAdvert } from "../../api";
import { debounce } from 'lodash';
import Check from "@mui/icons-material/Check";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Menu from "@mui/joy/Menu";
import Textarea from "@mui/joy/Textarea";
import { MenuItem, Stack, TextField } from "@mui/material";

export const EditDescription = () => {
  const { register, setValue } = useFormContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const advert = useStore((state) => state.advertDetails);
  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails)


  const handleUpdate = useCallback((token, advertId, key, value) => {
    return updateAdvert(token, { id: advertId, key, value });
  }, []);

  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, key, value) => {
      handleUpdate(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );

  const handleChangeOverAll = (key) => async(event) => {
    const value = event.target.value;
    // Update the form value
    setValue(key, value);
    updateAdvertDetails(key, value)
    // Call the debounced function
    debouncedUpdateAdvert(token, advertId,key, value)
  };

  return (
    <>
      <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
        Bechreibung
      </h2>
      <Stack
        sx={{ marginBottom: "16px" }}
        direction={{ xs: "colum", md: "row" }}
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("adTitle")}
          onChange={handleChangeOverAll("adTitle")}
          required
          defaultValue={advert.addTitle}
          sx={{
            marginBottom: "16px",
            width: "100%",
            "@media (min-width: 900px)": { width: "100%" },
          }}
          id="outlined-required"
          label="Titel"
        ></TextField>
        <Textarea
          {...register("adDescription")}
          onChange={handleChangeOverAll("adDescription")}
          defaultValue={advert.adDescription}
          placeholder="Type something here…"
          sx={{
            width: "100%",
          }}
          minRows={3}
          endDecorator={
            <Box
              sx={{
                display: "flex",
                gap: "var(--Textarea-paddingBlock)",
                pt: "var(--Textarea-paddingBlock)",
                borderTop: "1px solid",
                borderColor: "divider",
                flex: "auto",
              }}
            >
              <IconButton
                variant="plain"
                color="neutral"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <FormatBold />
                <KeyboardArrowDown fontSize="md" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                size="sm"
                placement="bottom-start"
                sx={{ "--ListItemDecorator-size": "24px" }}
              >
                {["200", "normal", "bold"].map((weight) => (
                  <MenuItem
                    key={weight}
                    selected={fontWeight === weight}
                    onClick={() => {
                      setFontWeight(weight);
                      setAnchorEl(null);
                    }}
                    sx={{ fontWeight: weight }}
                  >
                    <ListItemDecorator>
                      {fontWeight === weight && <Check fontSize="sm" />}
                    </ListItemDecorator>
                    {weight === "200" ? "lighter" : weight}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                variant={italic ? "soft" : "plain"}
                color={italic ? "primary" : "neutral"}
                aria-pressed={italic}
                onClick={() => setItalic((bool) => !bool)}
              >
                <FormatItalic />
              </IconButton>
            </Box>
          }
        />
      </Stack>
    </>
  );
};

export default EditDescription;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const clientId = process.env.REACT_APP_OAUTH_CLIENT_I;

console.log(clientId);

export const createAdvert = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/advert/create`, payload, {
      headers: {
        "Content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Advert"
    );
  }
};

export const handleRegistration = async (username, email, subAuth) => {
  try {
    const response = await axios.post(
      `${API_URL}/user/register`,
      {
        username,
        email,
        subAuth,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Registration failed");
    }

    const userData = response.data;
    console.log("User registered:", userData);
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Registration"
    );
  }
};  

export const handelFileUpload = async (formData, advertId) => {
  try {
    const response = await axios.post(`${API_URL}/image/uploads/${advertId}`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    const upload = response;
    console.log("Upload Succecfully:", upload);
    return upload;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Upload"
    );
  }
};

export const handleVideoFileUpload = async (formData, advertId) => {
  try {
    const response = await axios.post(`${API_URL}/video/uploads/${advertId}`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    }); 
    const upload = response;
    return upload;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Upload"
    );
  }
};

//Sub as Id because on Atho0 the user will be identified through "sub"
export const getUser = async ( sub) => {
  try {
    const response = await axios.get(`${API_URL}/user/sub/${sub}`, {
      params: { sub },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error finding user by sub:", error);
    throw error;
  }
};


export const getTokenFromUuid = async ( uuid) => {
  try {
    const response = await axios.get(`${API_URL}/auth/uuid/${uuid}`, {
      headers: {
        "Content-Type": "application/json"
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error finding user by sub:", error);
    throw error;
  }
};


export const getAccessToken = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/token`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response)
    return response.data;
  } catch (error) {
    console.error("Error finding user by sub:", error);
    throw error;
  }
};

export const getUsersAds = async (token,userId) => {
  try {
    const response = await axios.get(`${API_URL}/user/adverts/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error finding Ads by user:", error);
    throw error;
  }

};

export const getSingleAdvert = async (token,advertId) => {
  try {
    const response = await axios.get(`${API_URL}/advert/${advertId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error finding Ads:", error);
    throw error;
  }

};





export const deleteAdvert = async (token,advertId) => {
  try {
    const response = await axios.delete(`${API_URL}/advert/${advertId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        
      },
    });
    return response;
  } catch (error) {
    console.error("Error finding Ads by user:", error);
    throw error;
  }

};

export const updateAdvert = async (token, { id, key, value }) => {
  try {
    const response = await axios.patch(`${API_URL}/advert/${id}`, { [key]: value } , {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        
      },
    });

    console.log(`Successfully updated ${key}:`, response.data);
  } catch (error) {
    console.error(`Error updating ${key}:`, error);
  }
};

export const updateAdvertMultiFields = async (token, { id, updates }) => {
  try {
    const response = await axios.patch(`${API_URL}/advert/${id}`, updates, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    console.log(`Successfully updated advert:`, response.data);
  } catch (error) {
    console.error(`Error updating advert:`, error);
  }
};


export const updateAdvertTwo = async (token,id, data) => {
  try {
    const response = await axios.patch(`${API_URL}/advert/${id}`, data , {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        
      },
    });

    console.log(`Successfully updated`, response.data);
  } catch (error) {
    console.error(`Error updating:`, error);
  }
};

export const deleteImage = async (id, filename) => {
  try {
    const response = await axios.delete(`${API_URL}/image/${id}`, {filename}, {
      headers: {
        "Content-Type": "application/json"
        // "Authorization": `Bearer ${token.access_token}`
      }
    });

    console.log(`Successfully deleted image with id ${id}:`, response.data);
  } catch (error) {
    console.error(`Error deleting image with id ${id}:`, error);
  }
};

export const deleteVideo = async (id, filename) => {
  try {
    const response = await axios.delete(`${API_URL}/video/${id}`, {filename}, {
      headers: {
        "Content-Type": "application/json"
        // "Authorization": `Bearer ${token.access_token}`
      }
    });

    console.log(`Successfully deleted video with id ${id}:`, response.data);
  } catch (error) {
    console.error(`Error deleting video with id ${id}:`, error);
  }
};

export const findAdvertImages = async (token,advertId) => {
  try {
    const response = await axios.get(`${API_URL}/advert/${advertId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
    return response;
  } catch (error) {
    console.error("Error finding Ads by user:", error);
    throw error;
  }

};




export const getAccessTokenFromCode = async (authCode) => {
  const reqObject = { code: authCode };
  try {
    const response = await axios.post(`${API_URL}/auth/code`,  { code: authCode }, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Get Token From Code"
    );
  }
};


export const handleFileUpdate = async (token,formData) => {
  try {
    const response = await axios.patch(`${API_URL}/image/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}` // Ensure the token is available
      }
    });
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};



export const createSlideshow = async (advertInfo, user) => {
  try {
    const response = await axios.post(`${API_URL}/advert/slideshow`, advertInfo, {
      headers: {
        "Content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An error occurred Advert"
    );
  }
};






import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    isAuthenticated,
    user,
    logout,
  } = useAuth0();

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  let avLetters = null;

  if(user) {
    avLetters = user.nickname.split('.')[0].charAt(0).toUpperCase() + user.nickname.split('.')[1].charAt(0).toUpperCase()
  }

  return (
    <div className="w-full border-b border-gray-500 bg-white py-5 px-5 sticky z-50 top-0">
      <nav className="container mx-auto max-w-4xl flex justify-between items-center">
        <Link className="hover:text-sky-700 hover:duration-100" to="/">
          <img
            width={120}
            src="https://d2ihwlkw7ehx6k.cloudfront.net/logo.png"
            alt="Logo"
          />
        </Link>
        <div className="hidden md:flex justify-center">
          <ul className="flex gap-8 font-medium items-center">
            <li className="hover:text-sky-700 hover:duration-100">
              <Link to="/dashboard">Meine Inserate</Link>
            </li>
            <li className="hover:text-sky-700 hover:duration-100">
              <Link to="/insertion">Inserat erstellen</Link>
            </li>
            {isAuthenticated && (
              <>
                <li className="flex items-center">
                  <button
                    onClick={() => logout()}
                    className="flex items-center text-white bg-blue-500 p-2 rounded-md hover:bg-blue-600 transition duration-200"
                  >
                    <LogoutIcon className="mr-2" />
                    Abmelden
                  </button>
                </li>
                <Avatar>{avLetters}</Avatar>
              </>
            )}
          </ul>
        </div>
        <div className="md:hidden flex items-center">
          <IconButton onClick={toggleMobileMenu}>
            <MenuIcon />
          </IconButton>
        </div>
      </nav>
      {mobileMenuOpen && (
        <div className="fixed max-h-fit	 inset-0 bg-white shadow-lg z-40 md:hidden">
          <div className="flex justify-between items-center p-4 border-b">
          <Link to="/" onClick={toggleMobileMenu}>
              <img
                width={100}
                src="https://d2ihwlkw7ehx6k.cloudfront.net/logo.png"
                alt="Logo"
              />
            </Link>
            <IconButton onClick={toggleMobileMenu}>
              <CloseIcon />
            </IconButton>
            
          </div>
          <ul className="flex flex-col p-4">
            <li className="py-2 ">
              <Link to="/dashboard" onClick={toggleMobileMenu} className="block hover:text-sky-700">Meine Inserate</Link>
            </li>
            <li className="py-2">
              <Link to="/insertion" onClick={toggleMobileMenu} className="block hover:text-sky-700">Inserat erstellen</Link>
            </li>
            {isAuthenticated && (
              <>
                <li className="py-2 order-2 flex flex-row justify-center">
                  <button
                    onClick={() => { logout(); toggleMobileMenu(); }}
                    className="flex items-center w-full text-left bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
                  >
                    <LogoutIcon className="mr-2" />
                    Abmelden
                  </button>
                </li>
                <li className="py-2 -order-5 flex flex-row justify-center">
                  <Avatar>{avLetters}</Avatar>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
